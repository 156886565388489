import './PlanilhaBeneficiado.css'
import React, { useEffect, useState } from 'react';
import { formatarData } from '../../Utilitarios/utilitario_de_data';
import { dataAmericana } from '../../Utilitarios/formatarDataFormatoAmericano';
import { Link } from 'react-router-dom';
import axios from '../../../Configuracoes/axiosConfig';
import Modal from 'react-modal';

const PlanilhaBeneficiado = () => {
 
  const [sugestoes, setSugestoes] = useState([]);
  const [inputSearch, setInputSearch] = useState(''); // Estado para lidar com o valor do input produtor
  const [selectedItem, setSelectedItem] = useState(null);
  const [loteParaEditar, setLoteParaEditar] = useState(null);
  const [confirmarEdicao, setConfirmarEdicao] = useState(false);
  const [lotesConsultados, setLotesConsultados] = useState([]); //Estado para armazenar os lotes filtrados com base no produtor consultado
  const [consultarPressionado, setConsultarPressionado] = useState(false); //Variável de valor booleano que verifica se o botão "Consultar" foi pressionado ou não. É inicializada como 'false'.
  const [dataSelecionada, setDataSelecionada] = useState('');
  const [mostrarTelaConfirmacao, setMostrarTelaConfirmacao] = useState(false);
  const [showModalLancado, setShowModalLancado] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState(null);
  const [showModalDesmarcado, setShowModalDesmarcado] = useState(false);
  
  // const [resultadosConsulta, setResultadosConsulta] = useState([]);
  // const [exibirReferencia, setExibirReferencia] = useState(false); //Estado para controlar a exibição do campo "Referência"com base na opção selecionada no select. É inicializado como 'false'.
 
  // Estado para controlar os dados inseridos
  const [dados, setDados] = useState([]);
  const [novoItem, setNovoItem] = useState({ // Estado para inserir um novo item
    produtor: '',
    apelido: '',
    meieiro: '',
    data: '',
    sacas: '',
    quilos: '',
    barracao: '',
    subproduto: '',
    observacoes: ''
  });
  
  useEffect(() => {
    if (mostrarTelaConfirmacao) {
      abrirModalConfirmacao();
    }
  }, [mostrarTelaConfirmacao]);

  //Função para buscar lotes de Café Beneficiado
  const buscarDados = async () => {
    try{
      const response = await axios.get('http://193.203.183.215:8080/cafebeneficiado');
      const dadosRecebidos = response.data; 
      setDados(dadosRecebidos); // Definindo o estado 'dados' diretamente com a resposta da API
      console.log(dadosRecebidos); //Verificando o retorno da API
      
      return dadosRecebidos; //Retorna os dados para serem usados posteriormente      
    }catch(error){
      console.error('Erro ao buscar um Café Beneficiado!', error);
      return []; //Retorna um array vazio em caso de erro
    }
  };

  // Função para adicionar um novo item à tabela
  const handleInserirItem = async () => {

    //Verifica se os campos obrigatórios estão preenchidos
    if( 
        novoItem.produtor === '' ||
        novoItem.sacas === '' ||
        novoItem.quilos === '' ||
        novoItem.data === ''      
    ){
      alert('Preencha os campos produtor, sacas, quilos e data para cadastrar!');
      return;
    }

    try {
        //Enviar dados para o backend
        await axios.post('http://193.203.183.215:8080/cafebeneficiado', novoItem);
     
        //atualizar a lista de dados após a inserção
        buscarDados();

        //limpa os campos após a inserção
        setNovoItem({
          produtor: '',
          apelido: '',
          meieiro: '',
          data: '',
          sacas: '',
          quilos: '',
          barracao: '',
          subproduto: '',
          observacoes: ''
        });
    } catch(error){
      console.error('Erro ao inserir item:', error);
    }
    //Extraindo referencias do input referencia(s) e dividindo-os
    const references = novoItem.referencia.split(' ');

        // Verifica se há 2 ou mais referencias no input
        if (references.length >= 2){

          //Buscando os lotes de Café Beneficiado baseado nas referências
          const coffeMaqLotsPromisses = references.map(async (reference) => {
            //Remove o prefixo 'E-' para cada referência antes de usá-lo na URL
            const cleanReference = reference.replace(/^E-?/, ''); //Remove 'E-' ou 'E'

            const response = await axios.get(`http://193.203.183.215:8080/cafebeneficiado/${cleanReference}`);
            return response.data;
          });

          //Aguardando a conclusão de todas as solicitações de busca
          const coffeMaqLots = await Promise.all(coffeMaqLotsPromisses);

          //Extraindo produtores de lotes buscados de Café Máquina
          const coffeMaqProducers = coffeMaqLots.map((lot) => lot.produtor);

          //Verificando se todos os produtores de Café Máquina correspondem ao produtor em novoItem
          const isMatching = coffeMaqProducers.every((producer) => producer === novoItem.produtor);

          if(!isMatching){
          // Exibir mensagem de erro ao usuário
          alert('Os produtores dos lotes selecionados de Café Beneficiado não são os mesmos!');
          return;
          }
        }

        /* Verifica se os campos obrigatórios estão preenchidos  */
        //   if( 
        //       novoItem.produtor === '' ||
        //       novoItem.sacas === '' ||
        //       novoItem.quilos === '' ||
        //       novoItem.data === ''      
        //   ){
        //     alert('Preencha os campos produtor, sacas, quilos e data para cadastrar!');
        //     return;
        //   }

        //   try {
        //       //Enviar dados para o backend
        //       await axios.post('http://193.203.183.215:8080/cafebeneficiado', novoItem);

        //       //atualizar a lista de dados após a inserção
        //       buscarDados();

        //       //limpa os campos após a inserção
        //       setNovoItem({
        //         produtor: '',
        //         apelido: '',
        //         meieiro: '',
        //         data: '',
        //         sacas: '',
        //         quilos: '',
        //         barracao: '',
        //         subproduto: '',
        //         observacoes: ''
        //     });
        //   } catch(error){
        //     console.error('Erro ao inserir item:', error);
        //   }
  }

  // Função para lidar com a mudança no campo "Produtor"
  const handleProdutorChange = (e) => { // Chama `handleFilter` e `setNovoItem` ao mesmo tempo
    const novoValor = e.target.value;
    setNovoItem({ ...novoItem, produtor: novoValor });
    handleFilter(e); // Chama a função handleFilter aqui para atualizar sugestões
  };

    useEffect(() => { //dispara uma função toda vez que uma variável muda
      buscarDados();
    }, []);

    useEffect(() => { //Lógica para buscar sugestões de produtores com base em 'inputSearch'
      buscarSugestoes();
  }, [inputSearch]);

    const buscarSugestoes = async () => {
      if (inputSearch.length >= 3) {
        try{
          const response = await axios.get(`http://193.203.183.215:8080/produtor/filter?nome=${inputSearch}`);
          setSugestoes(response.data); //Salva o resultado das sugestões retornados após a busca de nomes de produtores filtrados
        } catch (error) {
          console.error('Erro ao buscar sugestões de produtores', error);
          setSugestoes([]);
        }
      } else {
        setSugestoes([]);
      }
    }

    const handleSelecionarSugestao = (sugestao) => { // Função que seleciona o produtor sugerido ao clicar nele
      console.log("Produtor Selecionado:", sugestao.nome);
      setInputSearch(sugestao.nome); //Seleciona o nome da sugestão escolhido
      setSugestoes([]); // Limpa as sugestões após selecionar uma sugestão
      setNovoItem({ ...novoItem, produtor: sugestao.nome });
    }

    const handleFilter = (event) => { //atualiza o valor do estado inputSearch com o valor do input que foi alterado. O estado inputSearch
      setInputSearch(event.target.value); //é usado para armazenar o valor do input "Produtor"
    }

    // Função para abrir o modal de confirmação de alteração
    const abrirModalConfirmacao = () => {
      //console.log('abrirModalConfirmacao() foi chamada!');
      //Verifica se os campos obrigatórios (produtor, sacos, quilos e data) não estão vazios
      if( novoItem.produtor === '' || 
          novoItem.sacas === '' ||
          novoItem.quilos === '' ||
          novoItem.data === ''                
      ){
        alert('Preencha os campos produtor, sacas, quilos e data para Editar!');        
      }
      else{  //Se os campos obrigatórios ñ estiverem vazios...
          if (selectedItem) {
            console.log('selectedItem foi selecionado!');
           if(!mostrarTelaConfirmacao){
             setMostrarTelaConfirmacao(true);
           } 
          setLoteParaEditar(selectedItem);
          setConfirmarEdicao(true);
        }
        else{
          console.log('selectedItem não foi selecionado! :(');
        }
      }
    }

    // Função de manipulação de click na linha da tabela para carregar dados nos inputs ao clicar na linha
    const clickLinhaTabela = (item) => {
      const dataFormatada = dataAmericana(item.data);

        //atualiza o estado dataSelecionada com a data formatada em formato americano
        setDataSelecionada(dataFormatada);

        setSelectedItem(item); //rastreia o item (linha) selecionado na tabela

        //Preenche os campos de entrada com os dados da linha clicada
        setNovoItem({
          lote: item.lote,
          produtor: item.produtor,
          apelido: item.apelido,
          meieiro: item.meieiro,
          data: dataFormatada, // Define a data no formato "AAAA-MM-DD"
          sacas: item.sacas,
          quilos: item.quilos,
          barracao: item.barracao,
          referencia: item.referencia,
          subproduto: item.subproduto,
          observacoes: item.observacoes
        });
        
        setInputSearch(item.produtor); //Atualiza o valor do input search
        setLoteParaEditar(item); // Define o item selecionado no estado loteParaEditar      
        //abrirModalConfirmacao(item);
    }
      
    // Função para fechar o modal de confirmação de alteração
    const fecharTelaConfirmacao = () => {
      setLoteParaEditar(null);
      setConfirmarEdicao(false);
      setMostrarTelaConfirmacao(false);
    };

    const consultarLotes = () => {
     /* const nomeProdutor = novoItem.produtor.toLowerCase(); */ //Converte para minúsculas para comparação case-sensitive
      const lotesFiltrados = dados.filter(item => item.produtor.toLowerCase().includes(novoItem.produtor.toLowerCase()));
      
      setLotesConsultados(lotesFiltrados);
      setConsultarPressionado(true);

      /*const resultados = dados.filter(item =>item.produtor.toLowerCase().includes(nomeProdutor));
      setResultadosConsulta(resultados);*/

      /* setLotesConsultados(lotesFiltrados);
      setConsultarPressionado(true); */
    }

    //Função para confirmar a edição de lotes na tabela
  const confirmarEdicaoLote = async () => {
    
    //Certificando de que loteParaEditar não é nulo
    if (loteParaEditar) {
       
      console.log(`loteParaEditar: + ${JSON.stringify(loteParaEditar)}`);

      // Acessa os valores dos campos do formulário a partir do estado novoItem 
      const { lote, produtor, meieiro, data, sacas, quilos, barracao, status, subproduto, observacoes } = novoItem;
      console.log('novoLote: '+ JSON.stringify(novoItem));

      // Formata a data para o formato americano antes de enviar para o backend
      const dataFormatoAmericano = dataAmericana(novoItem.data);

      // Agora pode enviá-los para o servidor
      try{        
            const requestBody = {
            lote,
            produtor,
            meieiro,
            data: dataFormatoAmericano,
            sacas,
            quilos,
            barracao,
            status,
            subproduto,
            observacoes
        }

        await axios.put('http://193.203.183.215:8080/cafebeneficiado', requestBody);

        const dadosAtualizados = await buscarDados();
        setDados(dadosAtualizados);
                
        //Feche o modal de confirmação
        fecharTelaConfirmacao();

      } catch(error){
        console.error('Erro ao editar o lote: '+ loteParaEditar, error);
      }
    } else {
      alert('loteParaEditar na função "confirmarEdicaoLote" qdo clica no botão Editar está vazio');
    }
  }

    const abrirModalLancado = (item) => {
    setShowModalLancado(true);
    setItemSelecionado(item);
  }

  const fecharModalLancado = () => {
    setShowModalLancado(false);
  }

  const abrirModalCancelamento = (item) => {
    console.log("Abrir Modal Cancelamento chamado para o item:", item);
    setShowModalDesmarcado(true);
    setItemSelecionado(item);
  }

  const fecharModalCancelamento = () => {
    setShowModalDesmarcado(false);
  }

  const handleMarcarModalComoLancado = () => {
    if(selectedItem && selectedItem.lancado !== 'OK'){
      const lote = selectedItem.lote; //Obtém o número do lote

      try{
          axios.put('http://193.203.183.215:8080/cafebeneficiado', { 
           lote: lote, //Passa o número do lote para o servidor 
           lancado: 'sim'
        })
        
        buscarDados();
        buscarDados();
        
        fecharModalLancado();
        alert('Lote marcado como inserido na ficha do produtor!');        
      }
      catch(error) {
        console.error('Erro ao marcar lote como inserido:', error);
      }
  } else {
    fecharModalLancado();
   }
  }

  const handleDesmarcarModalComoLancado = () => {
    if(selectedItem && selectedItem.lancado !== ''){
      const bloco = selectedItem.lote;

      try{
          axios.put('http://193.203.183.215:8080/cafebeneficiado', {
            lote: bloco,
            lancado: 'nao'
          })

          fecharModalCancelamento();
          buscarDados();
          buscarDados();
          alert('Lote desmarcado como inserido na ficha do produtor!')
      } catch(error){
        console.error("Erro ao desmarcar lote:", error);
      }
    } else {
      console.log("Fora do 'if'");
      fecharModalCancelamento();
    }
  }

    return (
      <div className='div-b'>
        <Link to="/escritorio">Voltar</Link>

        <div className='div-label-b'>
          <h2>Café Beneficiado</h2>
        </div>
          {/* Inputs para cada atributo */}
          <div className='input-row-b'>
            <div className={`search-bar ${inputSearch.length > 0 ? 'active' : ''}`}> {/*Realiza uma operação de renderização condicional de classes CSS com base no valor da variável inputSearch.*/}
              <input
                value={inputSearch} //{novoItem.produtor}
                onChange= {handleProdutorChange}  //{(e) => setNovoItem({ ...novoItem, produtor: e.target.value })}
                type="text"
                placeholder="Produtor"
                name='produtor'
                className='prod-b'
              />  
              {sugestoes.length > 0 && (
               <ul>
                {sugestoes.map((sugestao) => (
                  <li key={sugestao.id} onClick={() => handleSelecionarSugestao(sugestao)}>
                    {sugestao.nome}
                  </li>
                 ))}
               </ul>
              )}              
            </div>              
              <input
                type="text"
                placeholder="Meieiro"
                value={novoItem.meieiro}
                onChange={(e) => setNovoItem({ ...novoItem, meieiro: e.target.value })}
                className='mei-b'
              />
              <br></br>
              <input
                type="date"
                placeholder="Data"
                value={novoItem.data}
                onChange={(e) => setNovoItem({ ...novoItem, data: e.target.value })}
                className='dat-b'
              />
              <input
                type="text"
                placeholder="Sacas"
                value={novoItem.sacas}
                onChange={(e) => setNovoItem({ ...novoItem, sacas: e.target.value })}
                className='sac-b'
              />
              <input
                type="text"
                placeholder="Quilos"
                value={novoItem.quilos}
                onChange={(e) => setNovoItem({ ...novoItem, quilos: e.target.value })}
                className='qui-b'
              />
              <input
                type="text"
                placeholder="Barracão"
                value={novoItem.barracao}
                onChange={(e) => setNovoItem({ ...novoItem, barracao: e.target.value })}
                className='bar-b'
              />
              <input
                type="text"
                placeholder="Mooca, Escolha, Painha..."
                value={novoItem.subproduto}
                onChange={(e) => setNovoItem({ ...novoItem, subproduto: e.target.value })}
                className='sub-b'
              />
            </div>
            <div className='input-row-b'>
              <input
                type="text"
                placeholder="Observações"
                value={novoItem.observacoes}
                onChange={(e) => setNovoItem({ ...novoItem, observacoes: e.target.value })}
                className='obs-b'
              />
            </div>
            <div className='div_b'>
              <button className='but_b' onClick={handleInserirItem}>Inserir</button>
              <button className='but2_b' onClick={abrirModalConfirmacao}>Editar</button>
              <button className='but3_b' onClick={consultarLotes}>Consultar</button>
            </div>

            <div className='table-b-container'>
              {/* Tabela para exibir os últimos registros */}
              <table className='table-b'>
                <thead>
                  <tr>
                    <th style={{ backgroundColor: '#DAA520', color: 'black'}}>Lançado</th>
                    <th style={{ backgroundColor: '#DAA520', color: 'black'}}>Lote</th>
                    <th style={{ backgroundColor: '#DAA520', color: 'black'}}>Data</th>
                    <th style={{ backgroundColor: '#DAA520', color: 'black'}}>Sacas</th>
                    <th style={{ backgroundColor: '#DAA520', color: 'black'}}>Quilos</th>
                    <th style={{ backgroundColor: '#DAA520', color: 'black'}}>Produtor</th>
                    <th style={{ backgroundColor: '#DAA520', color: 'black'}}>Meieiro</th>            
                    <th style={{ backgroundColor: '#DAA520', color: 'black'}}>Barracao</th>
                    <th style={{ backgroundColor: '#DAA520', color: 'black'}}>Mooca - Escolha - Painha</th>
                    <th style={{ backgroundColor: '#DAA520', color: 'black'}}>Observacoes</th>
                  </tr>
                </thead>
                <tbody>
                  {consultarPressionado
                    ? lotesConsultados.reverse().map((item, index) => (
                  <tr key={index} onClick={() => clickLinhaTabela(item)} className={selectedItem === item ? 'selected-row-b' : ''}>
                      <td style={{backgroundColor: item.lancado === 'sim' ? '#00FF00' : '', fontWeight: 'bold'}} onClick={() => {if (item.lancado !== 'sim') {abrirModalLancado(item)} else {abrirModalCancelamento(item)}}}>{item.lancado === 'nao' ? '' : 'OK'}</td>
                      <td>{'E-0'+ item.lote}</td>
                      <td>{formatarData(item.data)}</td>
                      <td style={{textAlign: 'center'}}>{item.sacas}</td>
                      <td style={{textAlign: 'center'}}>{item.quilos}</td>
                      <td style={{textAlign: 'center'}}>{item.produtor}</td>
                      <td>{item.meieiro}</td>
                      <td>{item.barracao}</td>
                      <td>{item.subproduto}</td>
                      <td>{item.observacoes}</td>
                    </tr> 
                    ))
                    :dados.reverse().map((item, index) => (
                    // Renderiza os resultados da consulta quando há consulta
                    <tr key={index} onClick={() => clickLinhaTabela(item)} className={selectedItem === item ? 'selected-row-b' : ''}>
                      <td style={{backgroundColor: item.lancado === 'sim' ? '#00FF00' : '', fontWeight: 'bold'}} onClick={() => {if (item.lancado !== 'sim') {abrirModalLancado(item)} else {abrirModalCancelamento(item)}}}>{item.lancado === 'nao' ? '' : 'OK'}</td>
                      <td>{'E-0'+ item.lote}</td>
                      <td>{formatarData(item.data) }</td>
                      <td>{item.sacas}</td>
                      <td>{item.quilos}</td>
                      <td>{item.produtor}</td>
                      <td>{item.meieiro}</td>
                      <td>{item.barracao}</td>
                      <td>{item.subproduto}</td>
                      <td>{item.observacoes}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

      {/* Modal de confirmação */}
      { mostrarTelaConfirmacao && (  // Se confirmarEdicao for 'true' e modalConfirmacao - aí renderiza
           <div className="modal-confirmacao">
              <div className="modal-content-b">
                <p>Tem certeza que deseja alterar os dados desse lote?</p>
                <button onClick={confirmarEdicaoLote}>Sim</button>
                <button onClick={fecharTelaConfirmacao}>Não</button>
              </div>
           </div>
          )}
      <div>
        <Modal isOpen={showModalLancado} className="modal-b" overlayClassName="modal-overlay-b" onRequestClose={fecharModalLancado} contentLabel="Confirmação">
          <div className='modal-content-b'>
            <h2>Deseja marcar este lote como já inserido na ficha do produtor?</h2>
            <button className="modal-button-b" id="simButton-b" onClick={handleMarcarModalComoLancado}>Sim</button>
            <button className="modal-button-b" id="naoButton-b" onClick={fecharModalLancado}>Não</button>
          </div> 
        </Modal>
      </div>

      <div>
        <Modal isOpen={showModalDesmarcado} className="modal-e" overlayClassName="modal-overlay-e" onRequestClose={fecharModalCancelamento} contentLabel='Cancelamento'>
          <div className='modal-content-e'>
              <h2>Deseja desmarcar este lote como já inserido na ficha do produtor?</h2>
              <button className='modal-button-e' id="simButton-e" onClick={handleDesmarcarModalComoLancado}>Sim</button>
              <button className='modal-button-e' id='naoButton-e' onClick={fecharModalCancelamento}>Não</button>
          </div>
        </Modal>
      </div>  
    </div>
  );
};

export default PlanilhaBeneficiado;