import './Escritorio.css'
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom' //useHistory para navegar entre páginas
import cpf from 'cpf';
import axios from '../../Configuracoes/axiosConfig'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const Escritorio = () => {
    const [selectedOption, setselectedOption] = useState(null);
    const menuOptions = ['Ficha Produtor', 'Planilhas Armazém', 'Liga', 'Relatórios', 'Administrativo'];
    const produtoroptions = ['Adicionar Novo Produtor', 'Adicionar Dados Produtor', 'Lançar Café na Ficha', 'Adiantamento', 'Adicionar Entrada / Saída', 'Saldo Produtor'];
    const tipoConta = ['Conta Corrente', 'Conta Poupança'];
    const history = useHistory(); // Obtém a instância do histórico de navegação
    const [showFichaProdutor, setShowFichaProdutor] = useState(false); //Estado para controlar renderização da Ficha de Produtor
    const [selectedProdutorOption, setSelectedProdutorOption] = useState(false);
    const [selectedTipoConta, setSelectedTipoConta] = useState('');
    const [showCamposProdutor, setShowCamposProdutor] = useState(false);
    const [cadastroSucesso, setCadastroSucesso] = useState(false); //Estado para inserir msg 'Cadastro realizado com sucesso' na tela
    const [erroCadastro, setErroCadastro] = useState(false);
    const [showAlterarProdutor, setShowAlterarProdutor] = useState(false);
    
    const [produtorData, setProdutorData] = useState({
      nome: '',
      telefone: '',
      cpf: '',
      banco: '',
      agencia: '',
      tipo_conta: '',
      numero_conta: '',
      chave_pix: '',
      nome_correntista: '',
      data: new Date().toISOString().slice(0, 10) // Adicionando a data atual
    });

    const tipo_conta = [
     {
      value: 'Conta Corrente',
      label: 'Conta Corrente',
     },
     {
      value: 'Conta Poupança',
      label: 'Conta Poupança',
     },
    ];

      const optionChange = (option) => { // Verifica qual opção foi selecionada no select
        setselectedOption(option);      
        
        if (option === 'Ficha Produtor'){ // Verifica se a opção selecionada é "Ficha Produtor"
          setShowFichaProdutor(true);
          setShowCamposProdutor(false); // Esconde os campos de produtor
          //history.push('/fichaprodutor');
        } else {
          // Se a opção selecionada não for "Ficha Produtor", esconde o segundo select
        setShowFichaProdutor(false);
        setShowCamposProdutor(false); // Esconde os campos de produtor
        }

        //Verifica se a opção selecionada é "Planilhas Barracão"
        if (option === 'Planilhas Armazém'){
        // Navegar para outra página
        history.push('/planilhas'); //Rota desejada
        }
      }  

      const produtorOptionChange = (option) => { //Verifica qual opção foi selecionada para o menu de produtores
        setSelectedProdutorOption(option);
        
        if(option === 'Adicionar Novo Produtor') { //Se foi adicionado no segundo menu a opção 'Adicionar Novo Produtor'
          setShowCamposProdutor(true); // Mostrar os campos de produtor
          setShowAlterarProdutor(false);
        } else if(option === 'Adicionar Dados Produtor') {
          setShowCamposProdutor(false); // Esconder os campos de produtor
          setShowAlterarProdutor(true); // Mostrar os campos de alterar o produtor
        }
      }

      // const tipoContaOptionChange = (tipoContaSelecionado) => { //Verifica qual opção foi selecionada para o menu de tipo de conta
      //   setSelectedTipoConta(tipoContaSelecionado);
      // }

      // const handleProdutorDataChange = (e) => { //Esta função é um evento de manipulação que é chamado sempre que há uma alteração nos campos de entrada de dados do formulário no componente React.
      //   const { name, value } = e.target;
      //   // Atualiza o estado local dos inputs
      //   setProdutorData({
      //     ...produtorData,
      //     [name]: value
      //   });
      //   //Atualiza o estado principal produtorData
      //   setProdutorData(prevState => ({
      //     ...prevState,
      //     [name]: value
      //   }));
      // }

      const handleAdicionarClick = async () => {
        
        if( produtorData.nome === ''){
          alert("Preencha o campo Nome para efetuar o cadastro!");
        } else {

            try{
                await axios.post('http://193.203.183.215:8080/produtor', produtorData);
                
                //limpa os campos após a inserção
                setProdutorData({
                  nome: '',
                  telefone: '',
                  cpf: '',
                  banco: '',
                  agencia: '',
                  tipo_conta: '',
                  numero_conta: '',
                  chave_pix: '',
                  nome_correntista: ''
                });
                console.log('Produtor cadastrado com sucesso!');
                alert('Produtor cadastrado com sucesso!');
              
              } catch (error){
                console.error('Erro ao inserir item!', error);
                alert('Erro ao adicionar produtor!');
              }
        }
      }

    const formatPhonenumber = (value) => {
      // Remove todos os caracteres que não são números, '(' , ')' e '-'
      return value.replace(/[^\d() -]/g, '');
    }    

    const cleanedValue = (value) => { //Permite a digitação apenas de números, '-' e '.'
       return value.replace(/[^\d.-]/g, '');
    }

    const truncatedValue = (value) => { //Permite a digitação de no máximo 14 dígitos (quantidade do CPF brasileiro)
      return value.slice(0-14);
  }
  
  const handleTipoContaChange = (event) => {
    setSelectedTipoConta(event.target.value);
   }
  
  return (
    <div className='fr'>
      <div className='menu-container'>        
        <select value={selectedOption || ''} // '' se selectedOption for null
          onChange={(e) => optionChange(e.target.value)} //Verifica qual opção foi selecionada no select
          className='dropdown-menu'>
            <option value=''>Selecione uma opção</option> {/* Opção em branco como item padrão */}
              {menuOptions.map((option, index) =>(
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
        </select>
      </div>
      <h1 className='h'> Escritório Minas Café </h1>
      {showFichaProdutor && (
       <div>
        <div className='ar'>
          <h3>Área de Produtor</h3>
        </div>
        <div className='menu-container2'> 
          <select value={selectedProdutorOption || ''}
            onChange={(e) => /*setSelectedProdutorOption*/ produtorOptionChange(e.target.value)}
            className='dropdown-menu'>
             <option value=''>Selecione uma opção</option>
             {produtoroptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        {showCamposProdutor && (
          <div className='campos-produtor'>
            <div>
              <h3 className='cad'>Cadastrar Produtor</h3>
            </div>
            <div className='div_nome_tel'>
                <TextField required id="text_nome" className="text_nome" label="Nome:" defaultValue="text" variant="outlined" value={produtorData.nome}
                onChange={(e) => setProdutorData({ ...produtorData, nome: e.target.value })} style={{ marginBotton:'10px', marginRight: '10px' }}/>
                
                {/* <label className='name_label'>Nome:</label>
                <input type='text' name='text_name' className='text_name' value={produtorData.nome} onChange= {(e) => setProdutorData({ ...produtorData, nome: e.target.value})} /> */}
                
                <TextField required id="tel_label" className='tel_label' label="Telefone:" defaultValue="text" variant="outlined" value={produtorData.telefone}
                onChange={(e) => setProdutorData({ ...produtorData, telefone: formatPhonenumber(e.target.value) })}/>
            </div>
            <div className='div_cpf_ban_ag'>
                <TextField required id="cpf_text" className='cpf_text' label='CPF:' defaultValue="text" variant="outlined" value={produtorData.cpf}
                 onChange={(e) => setProdutorData({ ...produtorData, cpf: truncatedValue(e.target.value)})} style={{ marginBotton:'10px', marginRight: '10px' }} />
                {/* <label className='cpf_label'>CPF:</label>
                <input type='text' name='cpf_text' className='cpf_text' value={cleanedValue(truncatedValue(produtorData.cpf))} onChange={(e) => {setProdutorData({ ...produtorData, cpf: e.target.value})}} /> */}
              
                <TextField required id="banco_text" className='banco_text' label='Banco:' defaultValue="text" variant="outlined" value={produtorData.banco}
                onChange={(e) => setProdutorData({ ...produtorData, banco: e.target.value })} style={{ marginBottom: '10px', marginRight: '10px'}}/>
                {/* <label className='banco_label'>Banco:</label>
                <input type='text' name='banco_text' className='banco_text' value={produtorData.banco} onChange={(e) => setProdutorData({ ...produtorData, banco: e.target.value})} /> */}
              
                <TextField required id="agencia_text" className='agencia_text' label='Agência:' defaultValue="text" variant="outlined" value={produtorData.agencia}
                 onChange={(e) => setProdutorData({ ...produtorData, agencia: e.target.value })} />
                {/* <label className='ag_label'>Agência:</label>
                <input type='text' name='ag_text' className='ag_text' value={produtorData.agencia} onChange={(e) => setProdutorData({ ...produtorData, agencia: e.target.value})} /> */}

            </div>
            <div className='div_coren'>
               <TextField id="outlined-select-account" select label="Tipo de Conta" value={produtorData.tipo_conta} onChange={(e) => setProdutorData({ ...produtorData, tipo_conta: e.target.value})}
                  className='tipo-conta-select' helperText="Por favor selecione o tipo de conta" variant="outlined">
                  {tipo_conta.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
               </TextField> 
             
             {/* <select value={selectedTipoConta || ''} onChange={(e) => tipoContaOptionChange(e.target.value)} className='tipo-conta-menu'>
                <option value=''>Tipo de Conta</option>
                <option value='Conta Corrente'>Conta Corrente</option>
                <option valeu='Conta Poupança'>Conta Poupança</option>
              </select> */}

              <TextField required id="conta" className='num_cont' label="Número da Conta:" dafaultValue="text" variant="outlined" value={produtorData.numero_conta}
               onChange={(e) => setProdutorData({ ...produtorData, numero_conta: e.target.value })} style={{ marginBottom: '10px', marginLeft: '10px', textAlign: 'center'}}/>
              {/* <label className='num_con'>Número da Conta:</label>
              <input type='text' name='num_cont' className='num_cont' value={produtorData.numero_conta.toString()} onChange={(e) => setProdutorData({ ...produtorData, numero_conta: e.target.value})} /> */}
              
              <TextField required id="correntista" className='coren_text' label="Correntista:" defaultValue="text" variant="outlined" value={produtorData.nome_correntista}
               onChange={(e) => setProdutorData({ ...produtorData, nome_correntista: e.target.value })} style={{ marginLeft: '10px'}} />

              {/* <label className='coren_label'>Nome do Correntista:</label>
              <input type='text' name='coren_text' className='coren_text' value={produtorData.nome_correntista} onChange={(e) => setProdutorData({ ...produtorData, nome_correntista: e.target.value})} /> */}
            </div>
            <div className='div_pix'>
                <TextField required id="pix" className='pix_text' label="Chave Pix:" defaultValue="text" variant="outlined" value={produtorData.chave_pix}
                 onChange={(e) => setProdutorData({ ...produtorData, chave_pix: e.target.value})}/>
                {/* <label className='pix_label'>Chave Pix:</label>
                <input type='text' name='pix_text' className='pix_text' value={produtorData.chave_pix} onChange={(e) => setProdutorData({ ...produtorData, chave_pix: e.target.value})} /> */}
                
            </div>
            <button className='but_e' onClick={handleAdicionarClick}>Adicionar</button>

            < div className='cadastro-mensagem'>
               {cadastroSucesso && <p>Produtor cadastrado com sucesso!</p>}
            </div>  
            <div className='erro-mensagem'>
               {erroCadastro && <p>Erro ao cadastrar produtor</p>}
            </div>
          </div>
        )}
       </div>
      )}
      
      {showAlterarProdutor && (
       <div className='div_inic'>
         <h2>Verifique os dados do produtor a alterar: </h2>
         <TextField required id="nome_text" className='nome_text' label='Nome: ' defaultValue="text" variant='outlined' value={produtorData.nome} />
       </div>  
      
      )}
      <div className='footer-container'>
         <Link to="/paginainicial" className='bot'>
            <button className='button'>Voltar</button>
         </Link>
      </div>
    </div>
  );
}

export default Escritorio;