import './FormularioMaquina.css'
import React, { useState, useEffect } from 'react';
import { formatarData } from '../../Utilitarios/utilitario_de_data';
import { dataAmericana } from '../../Utilitarios/formatarDataFormatoAmericano';
import { Link } from 'react-router-dom';
import axios from '../../../Configuracoes/axiosConfig';
//import SearchBar from '../SearchBar'; //Navega para cima e depois para a pasta SearchBar
//import SugestaoProdutor from '../SugestaoProdutor';

const FormularioMaquina = () => {

const [sugestoes, setSugestoes] = useState([]);
const [inputSearch, setInputSearch] = useState('');
const [selectedItem, setSelectedItem] = useState(null); //estado para rastrear o item selecionado na tabela
const [loteParaEditar, setLoteParaEditar] = useState(null);
const [confirmarEdicao, setConfirmarEdicao] = useState(false);
const [resultadosConsulta, setResultadosConsulta] = useState([]);
const [lotesConsultados, setLotesConsultados] = useState([]); //'Estado' para armazenar os lotes filtrados com base no produtor consultado
const [consultarPressionado, setConsultarPressionado] = useState(false);
const [dataSelecionada, setDataSelecionada] = useState('');
const [exibirConfirmacaoEdicao, setExibirConfirmacaoEdicao] = useState(false);
const [mostrarTelaConfirmacao, setMostrarTelaConfirmacao] = useState(false);

// Estado para controlar os dados inseridos
const [dados, setDados] = useState([]);
const [novoItem, setNovoItem] = useState({ //Estado para inserir um novo item
  produtor: '',
  apelido: '',
  meieiro: '',
  data: '',
  sacas: '',
  quilos: '',
  barracao: '',
  referencia: '',
  subproduto: '',
  observacoes: ''
});
  
  useEffect(() => {
   if (mostrarTelaConfirmacao) {
     abrirModalConfirmacao(); // Chama a função para abrir o modal *******************************
   }
  }, [mostrarTelaConfirmacao]);

  //Função para buscar lotes de Café Máquina
  const buscarDados = async () => {
    try{
      const response = await axios.get('http://193.203.183.215:8080/cafemaquina');
      const dadosRecebidos = response.data;
      setDados(dadosRecebidos); // Definindo o estado 'dados' diretamente com a resposta da API
      console.log(dadosRecebidos); //Verificando o retorno da API

      return dadosRecebidos; //Retorna os dados para serem usados posteriormente      
    }catch(error){
      console.error('Erro ao buscar um Café Máquina!', error);
      return []; //Retorna um array vazio em caso de erro
    }
  };

  // Função para adicionar um novo lote de Café Maquina à tabela
  const handleInserirItem = async () => {

    //Verifica se os campos obrigatórios (Produtor, sacas, quilos e data) estão preenchidos
    if(
        novoItem.produtor === '' ||
        novoItem.sacas === '' ||
        novoItem.quilos === '' ||
        novoItem.data === ''
    ) {
       alert('Preencha os campos produtor, sacas, quilos e data para cadastrar!');
       return;
    }

    if(novoItem.referencia == '') { //Se o input text de referência estiver vazio
      alert('Não é possível criar um lote de café máquina sem inserir ao menos um lote de café em côco na referência!');
    } else {

      //Função para extrair referencias do input referencia(s) e dividí-las
      const references = novoItem.referencia.split(' ');
          
        //Função para verificar se os produtores dos lotes selecionados em 'Referência(s) são os mesmos'
        //Aqui verifica se foram inseridas 2 ou mais referências...  ******* Programar inserção de apenas 1 lote quando desejado  **********
        if (references.length >= 2){

          //Buscando os lotes de café em Côco baseado nas referências
          const coffeCocoLotsPromises = references.map(async (reference) => {
            //Remove o prefixo 'C-' para cada referencia antes de usá-lo na URL
            const cleanReference = reference.replace(/^C-?/, ''); //Remove 'C-' ou 'C'

            const response = await axios.get(`http://193.203.183.215:8080/cafecoco/${cleanReference}`);
            return response.data;
          });

          //Aguardando a conclusão de todas as solicitações de busca
          const coffeCocoLots = await Promise.all(coffeCocoLotsPromises);

          //Extraindo produtores de lotes buscados de Café Coco
         /* const coffeCocoProducers = coffeCocoLots.map((lot) => lot.produtor);

          //Verificando se todos os produtores de Café Coco correspondem ao produtor em novoItem
          const estaCoincidindo = coffeCocoProducers.every((producer) => producer === novoItem.produtor);

          if(!estaCoincidindo){
          // Exibir mensagem de erro ao usuário
          alert('Os produtores dos lotes selecionados de Café em Coco devem ser os mesmos para criar um lote de Café Máquina!');
          return;
          } */
        } else {
          alert('Você inseriu apenas um lote como referência!');
        }
      
        try{ 
            // Insere o novo item de Café Máquina usando axios.post
            await axios.post('http://193.203.183.215:8080/cafemaquina', novoItem);

            //atualizar a lista de dados após a inserção
            buscarDados();
          
            //limpar campos após a inserção
            setNovoItem({
              produtor: '',
              apelido: '',
              meieiro: '',
              data: '',
              referencia: '',
              sacas: '',
              quilos: '',
              barracao: '',
              subproduto: '',
              observacoes: ''
            });

            // *** Setando o status de lotes de Café Côco como 'Já Beneficiado', inserindo esses lotes como referências p/ o novo lote ***
            // *** de Café Máquina gerado e inserindo o novo lote de Café Máquina como referência para os lotes de Café(s) em Côco ***

            const novosDados = await buscarDados(); // Obtém os dados atualizados após a inserção
          
            // Pega o último item de Café Máquina inserido
            const ultimoItemInserido = novosDados[novosDados.length -1];

            if(ultimoItemInserido) { // Usando o último item inserido como referência para atualizar os lotes de 'Café Côco'
              
              const referencias = novoItem.referencia; // Obtém o valor do campo de entrada "Referência(s)" do novo item
              
              //Dividindo as referencias em lotes separados
              const lotes = referencias.split(' ');

              //Removendo os prefixos 'C-' ou 'C' dos lotes
              const lotesSemPrefixo = lotes.map(lote => lote.replace(/^C-?/, ''));

              //Atualizando os status dos lotes de Café Coco referenciados para 'Já beneficiado'
              for (const lote of lotesSemPrefixo) {
                const url = `http://193.203.183.215:8080/cafecoco`;
                const statusAtualizado = 'Já beneficiado';

              //Enviar a solicitação PUT para atualizar o status do lote no servidor para 'Já Beneficiado'
              await axios.put(url, {
                lote: lote,
                status: statusAtualizado,
                referencia: 'M-0'+ultimoItemInserido.lote // Usa o lote do último item inserido como referência
              });

              console.log(`Status de Café em Coco de lote ${lote} atualizado com sucesso!`);
              }
            }

          //setLotesInseridos([...lotesInseridos, novoItem]);
        } catch(error){
          console.error('Erro ao cadastrar lote de Café Máquina:', error);
        }
    }    
  };

  // Função para lidar com a mudança no campo "Produtor"
  const handleProdutorChange = (e) => { // Chama `handleFilter` e `setNovoItem` ao mesmo tempo
    const novoValor = e.target.value;
    setNovoItem({ ...novoItem, produtor: novoValor });
    handleFilter(e); // Chama a função handleFilter aqui para atualizar sugestões
  };

  useEffect(() => { //Dispara uma função toda vez que uma variável muda
    buscarDados(); // Lógica para buscar dados e atualizar dados aqui
  }, []);
  
  useEffect(() => { //Lógica para buscar sugestões de produtores com base em 'inputSearch'
    buscarSugestoes();
  }, [inputSearch]);

  const buscarSugestoes = async () => {
    if (inputSearch.length >= 3) {
      try{
        const response = await axios.get(`http://193.203.183.215:8080/produtor/filter?nome=${inputSearch}`);
        setSugestoes(response.data); //Salva o resultado das sugestões retornados após a busca de nomes de produtores filtrados
      } catch (error) {
        console.error('Erro ao buscar sugestões de produtores', error);
        setSugestoes([]);
      }
    } else {
      setSugestoes([]);
    }
  }

  const handleSelecionarSugestao = (sugestao) => { //Função que seleciona o produtor sugerido ao clicar nele
    console.log("Produtor selecionado:", sugestao.nome);
    setInputSearch(sugestao.nome); //Seleciona o nome da sugestão escolhida
    setSugestoes([]); // Limpa as sugestões após selecionar uma sugestão
    setNovoItem({ ...novoItem, produtor: sugestao.nome });
  };

   const handleFilter = (event) => { //atualiza o valor do estado inputSearch com o valor do input que foi alterado. O estado inputSearch
    setInputSearch(event.target.value); //é usado para armazenar o valor do input "Produtor".
   };

  // Função para abrir o modal de confirmação de alteração
  const abrirModalConfirmacao = () => {
    //console.log('abrirModalConfirmacao() foi chamada!');
    //Verifica se os campos obrigatórios (produtor, sacos, quilos e data) não estão vazios
    if (  novoItem.produtor === '' ||
          novoItem.sacas === '' ||
          novoItem.quilos === '' ||
          novoItem.data === ''
    ){
      alert('Preencha os campos produtor, sacas, quilos e data para continuar!');
    }else{ //Se os campos obrigatórios ñ estiverem vazios...
        if (selectedItem) {
          console.log('selectedItem foi selecionado!');
          if (!mostrarTelaConfirmacao){
            setMostrarTelaConfirmacao(true);
          }         
          setLoteParaEditar(selectedItem);
          setConfirmarEdicao(true);
      }
      else{
        console.log('selectedItem não foi selecionado! :(');
      }
    }
  }

  //Função de manipulação de clique na linha da tabela para carregar dados nos inputs ao clicar na linha
  const clickLinhaTabela = (item) => {
    const dataFormatada = dataAmericana(item.data);
        
      //atualiza o estado dataSelecionada com a data formatada em formato americano
      setDataSelecionada(dataFormatada);
      setSelectedItem(item); //rastreia o item (linha) selecionado na tabela

      //Preenche os campos de entrada com os dados da linha clicada
      setNovoItem({
        lote: item.lote,
        produtor: item.produtor,
        meieiro: item.meieiro,
        data: dataFormatada, // Define a data no formato "AAAA-MM-DD"
        sacas: item.sacas,
        quilos: item.quilos,
        barracao: item.barracao,
        referencia: item.referencia,
        subproduto: item.subproduto,
        observacoes: item.observacoes
      });
      
      setInputSearch(item.produtor); //Atualiza o valor do input search
      setLoteParaEditar(item); // Define o item selecionado no estado loteParaEditar      
      //abrirModalConfirmacao(item);
  }

  // Função para fechar o modal de confirmação de alteração
  const fecharTelaConfirmacao = () => {
    setLoteParaEditar(null);
    setConfirmarEdicao(false);
    setMostrarTelaConfirmacao(false);
  };

  const consultarLotes = () => {
    const lotesFiltrados = dados.filter(item => item.produtor.toLowerCase().includes(novoItem.produtor.toLowerCase()));
    setLotesConsultados(lotesFiltrados);
    setConsultarPressionado(true);
    
    /* const nomeProdutor = novoItem.produtor.toLowerCase();    
    const resultados = dados.filter(item => item.produtor.toLowerCase().includes(nomeProdutor));
    setResultadosConsulta(resultados); */
  }

  //Função para confirmar a edição
  const confirmarEdicaoLote = async () => {
    
    //Certificando de que loteParaEditar não é nulo
    if (loteParaEditar) {
      
      console.log(`loteParaEditar: + ${JSON.stringify(loteParaEditar)}`);

      // Acessa os valores dos campos do formulário a partir do estado novoItem
      const { lote, produtor, meieiro, data, sacas, quilos, barracao, referencia, subproduto, observacoes } = novoItem;
      console.log('novoLote: '+ JSON.stringify(novoItem));

      // Formata a data para o formato americano antes de enviar para o backend
      const dataFormatoAmericano = dataAmericana(novoItem.data);

      // Agora pode enviá-los para o servidor
      try{
            const requestBody = {
              lote,
              produtor,
              meieiro,
              data: dataFormatoAmericano,
              sacas,
              quilos,
              barracao,
              referencia,
              subproduto,
              observacoes
            }
        
          //Realiza a chamada PUT para atualizar o lote com os dados em requestBody
          await axios.put(`http://193.203.183.215:8080/cafemaquina`, requestBody);

          // Feche o modal de confirmação
          fecharTelaConfirmacao();
          
          setNovoItem ({
            lote: '',
            produtor: '',
            meieiro: '',
            data: '',
            sacas: '',
            quilos: '',
            referencia: '',
            subproduto: '',
            observacoes: ''
          });

          // *** Setando o status de lotes de Café Côco como 'Já Beneficiado', inserindo esses lotes como referências p/ o novo lote ***
          // *** de Café Máquina gerado e inserindo o novo lote de Café Máquina como referência para os lotes de Café(s) em Côco ***

          if(requestBody.referencia) { //Usando a referência para atualizar os lotes de 'Café Côco'
            const recebe = requestBody.referencia; // Obtém o valor do campo de entrada "Referência(s)" do novo item

            //Dividindo as referencias em lotes separados
            const blocos_cafe = recebe.split(' ');

            //Removendo os prefixos 'C-' ou 'C' dos lotes
            const lotessemprefixo = blocos_cafe.map(lote => lote.replace(/^C-?/,''));

            //Atualizar os lotes de Café Côco referenciados
            for(const lote of lotessemprefixo){
              const uri = `http://193.203.183.215:8080/cafecoco`;
              const statusAtual = 'Já beneficiado';
            
              //Enviar a solicitação 'PUT' para atualizar o status do lote de Café Côco para 'Já beneficiado'
                await axios.put(uri, {
                  lote: lote,
                  status: statusAtual,
                  referencia: 'M-0'+requestBody.lote //Usando o lote de Café Máquina selecionado como referência(s) do(s) lote(s) de Café Côco
                });
                console.log(`Status de Café em Coco de lote ${lote} atualizado com sucesso!`);
            }
          }
      } catch(error){
        console.error('Erro ao editar o lote: '+ loteParaEditar, error);
      }
    } else {
      alert('loteParaEditar na função "confirmarEdicaoLote" qdo clica no botão Editar está vazio');
    }
  }

  /* const handleEditarClick = () => {
    // Verifica se os campos obrigatórios estão preenchidos
    if (
        novoItem.produtor === '' ||
        novoItem.data === '' ||
        novoItem.sacas === '' ||
        novoItem.quilos === ''
    ) {
      alert('Preencha os campos Produtor, Data, Sacos e Quilos para Editar!');
    } else {
      // Se os campos estiverem preenchidos, exiba a tela de confirmação
      setExibirConfirmacaoEdicao(true);
    }
  }; */
  

  return (
    <div className='div'>
     <Link to="/paginainicial">Voltar</Link>  
      <div className='div_l'>
        <h2>Café Maquina</h2>
      </div>
        {/* Inputs para cada atributo */}
        <div className='input-row'>
            <div className={`search-bar ${inputSearch.length > 0 ? 'active' : ''}`}> {/*Realiza uma operação de renderização condicional de classes CSS com base no valor da variável inputSearch.*/}
              <input
                type="text"
                placeholder="Produtor"
                value={inputSearch} //{novoItem.produtor}
                onChange={handleProdutorChange}
                name='produtor'                
                //onChange={(e) => setNovoItem({ ...novoItem, produtor: e.target.value })}
                className='prod'
              />
              {sugestoes.length > 0 && (
                <ul>
                  {sugestoes.map((sugestao) => (
                   <li key={sugestao.id} onClick={() => handleSelecionarSugestao(sugestao)}>
                     {sugestao.nome}
                   </li>
                  ))}
                </ul>
              )}
            </div>
              <input
                type="text"
                placeholder="Meieiro"
                value={novoItem.meieiro}
                onChange={(e) => setNovoItem({ ...novoItem, meieiro: e.target.value })}
                name='meieiro'
                className='mei'
               />
              <br></br>
              <input
                type="date"
                placeholder="Data"
                value={novoItem.data}
                onChange={(e) => setNovoItem({ ...novoItem, data: e.target.value })}
                name='data'
                className='dat'
              />
            <input
                type="text"
                placeholder="Sacas"
                value={novoItem.sacas}
                onChange={(e) => setNovoItem({ ...novoItem, sacas: e.target.value })}
                name='sacas'
                className='sac'
              />
              <input
                type="text"
                placeholder="Quilos"
                value={novoItem.quilos}
                onChange={(e) => setNovoItem({ ...novoItem, quilos: e.target.value })}
                name='quilos'
                className='qui'
              />
              <input
                placeholder="Barracão"
                value={novoItem.barracao}
                onChange={(e) => setNovoItem({ ...novoItem, barracao: e.target.value })}
                name='barracao'
                className='bar'
              />
              <input
               type="text"
               placeholder="Referência(s)"
               value={novoItem.referencia}
               onChange={(e) => setNovoItem({ ...novoItem, referencia: e.target.value })}
               name='referencia'
               className='ref'
              />
              <input
                type="text"
                placeholder="Mooca, Escolha, Painha..."
                value={novoItem.subproduto}
                onChange={(e) => setNovoItem({ ...novoItem, subproduto: e.target.value })}
                name='subproduto'
                className='sub'
              />
        </div>
      <div className='input-row'>
            <input
              type="text"
              placeholder="Observações"
              value={novoItem.observacoes}
              onChange={(e) => setNovoItem({ ...novoItem, observacoes: e.target.value })}
              name='observacoes'
              className='obs'
            />
      </div>
      
      <div className='div_b'>
        <button className='but_m_b' onClick={handleInserirItem}>Inserir</button>
        <button className='but2_m_b' onClick={abrirModalConfirmacao}>Editar</button>
        <button className='but3_m_b' onClick={consultarLotes}>Consultar</button> {/*Chama a função de consulta*/}
      </div>

      <div className='table-container'>
          {/* Tabela para exibir os últimos registros */}
          <table className='table'>
            <thead> {/*Cabeçalho*/}
              <tr>
                <th style={{ backgroundColor: '#00FF00', color: 'black'}}>Lote</th>
                <th style={{ backgroundColor: '#00FF00', color: 'black'}}>Data</th>
                <th style={{ backgroundColor: '#00FF00', color: 'black'}}>Produtor</th>
                <th style={{ backgroundColor: '#00FF00', color: 'black'}}>Meieiro</th>
                <th style={{ backgroundColor: '#00FF00', color: 'black'}}>Sacas</th>
                <th style={{ backgroundColor: '#00FF00', color: 'black'}}>Quilos</th>
                <th style={{ backgroundColor: '#00FF00', color: 'black'}}>Barracao</th>
                <th style={{ backgroundColor: '#00FF00'}}>Referência</th>
                <th style={{ backgroundColor: '#00FF00', color: 'black'}}>Mooca - Escolha - Painha</th>
                <th style={{ backgroundColor: '#00FF00', color: 'black'}}>Observacoes</th>
              </tr>
            </thead>
            <tbody>
            {consultarPressionado
               ? lotesConsultados.reverse().map((item, index) => (
               <tr key={index} onClick={() => clickLinhaTabela(item)} //chama a função ao clicar na linha
                 className={selectedItem === item ? 'selected-row' : ''}> {/* aplica o estilo css à linha para destacá-la */}
                  <td>{'M-0'+item.lote}</td>
                  <td>{ formatarData(item.data) }</td>
                  <td>{item.produtor}</td>
                  <td>{item.meieiro}</td>
                  <td>{item.sacas}</td>
                  <td>{item.quilos}</td>
                  <td>{item.barracao}</td>
                  <td>{item.referencia}</td>
                  <td>{item.subproduto}</td>
                  <td>{item.observacoes}</td>
               </tr>
               ))
               : dados.reverse().map((item, index) => (
                // Renderiza os resultados da consulta quando há consulta
                <tr key={index} onClick={() => clickLinhaTabela(item)} className={selectedItem === item ? 'selected-row' : ''}>
                <td>{'M-0'+item.lote}</td>
                <td>{formatarData(item.data)}</td>
                <td>{item.produtor}</td>
                <td>{item.meieiro}</td>
                <td>{item.sacas}</td>
                <td>{item.quilos}</td>
                <td>{item.barracao}</td>
                <td>{item.referencia}</td>
                <td>{item.subproduto}</td>
                <td>{item.observacoes}</td>
              </tr>
             ))}
            </tbody>
          </table>
      </div>

      {/* Modal de confirmação */}
      { mostrarTelaConfirmacao && (  // Se confirmarEdicao for 'true' e modalConfirmacao - aí renderiza
       <div className="modal-confirmacao">
          <div className="modal-content">
            <p>Tem certeza que deseja alterar os dados desse lote?</p>
            <button onClick={confirmarEdicaoLote}>Sim</button>
            <button onClick={fecharTelaConfirmacao}>Não</button> 
          </div>
       </div>
      )}
    </div>
  );
};

export default FormularioMaquina;