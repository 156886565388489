import React from 'react';
import './FichaProdutor.css'

function fichaprodutor(){


    return(
     <div className='tex'>
       <h1>Você está na página de Ficha de produtor!</h1>
     </div>
    );
}

export default fichaprodutor;