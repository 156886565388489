import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Escritorio from './componentes/Escritorio'; // Componente do Escritório
import PaginaInicial from './PaginaInicial';
import barracao from './componentes/Barracao'; // Componente do barracão
import planilhas from './componentes/Escritorio/planilhas';
import PaginaNaoEncontrada from './PaginaNaoEncontrada'
import fichaprodutor from './componentes/Escritorio/FichaProdutor'
//import index from './index'
import login from './login';
import axios from '../src/Configuracoes/axiosConfig';
import NewUser from './componentes/Escritorio/NewUser';

function MainApp() {
 return (
   <Router>
    <Switch>  
      <Route path="/" exact component= {login}/>  {/* {NewUser} */}
      <Route path="/paginainicial" exact component={PaginaInicial}/>     {/* {login} */}
      <Route path="/barracao" exact component={barracao} />
      <Route path="/escritorio" exact component={Escritorio} />
      <Route path="/planilhas" exact component={planilhas} />
      <Route path="/fichaprodutor" exact component={fichaprodutor} />

      <Route component={PaginaNaoEncontrada} />
    </Switch>
   </Router>
 );
}

export default MainApp;