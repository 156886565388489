import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../src/Configuracoes/axiosConfig';
import { padding, textAlign } from '@mui/system';
import { lightBlue } from '@mui/material/colors';

function PaginaInicial() {
   useEffect(() => {
    document.title = 'Minas Café'; //Renderiza o título da aba do navegador
   });

   const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh', // Define a altura da tela inteira
   };

   const buttonStyle = {
     margin: '5px',
     width: '190px',
     height: '40px',
     textAlign: 'center'
   };

    return (
        <div style={containerStyle}>
          <h2>Escolha um setor:</h2>
            <Link to="/barracao">
              <button style={buttonStyle}>Armazém</button>
            </Link>
            <Link to="/escritorio">
              <button style={buttonStyle}>Escritorio</button>
            </Link>
            <div className='div_link' style={{ marginTop: '50px '}}>
             <Link style={{fontFamily: 'Book Antiqua', fontWeight: 'bold'}} to="/">SAIR</Link>
          </div>
        </div>
    );
}

export default PaginaInicial;